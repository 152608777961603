exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archivsuche-ergebnisse-tsx": () => import("./../../../src/pages/archivsuche-ergebnisse.tsx" /* webpackChunkName: "component---src-pages-archivsuche-ergebnisse-tsx" */),
  "component---src-pages-archivsuche-formular-tsx": () => import("./../../../src/pages/archivsuche-formular.tsx" /* webpackChunkName: "component---src-pages-archivsuche-formular-tsx" */),
  "component---src-pages-archivsuche-fragen-tsx": () => import("./../../../src/pages/archivsuche-fragen.tsx" /* webpackChunkName: "component---src-pages-archivsuche-fragen-tsx" */),
  "component---src-pages-archivsuche-tsx": () => import("./../../../src/pages/archivsuche.tsx" /* webpackChunkName: "component---src-pages-archivsuche-tsx" */),
  "component---src-pages-archivsuche-uebersicht-tsx": () => import("./../../../src/pages/archivsuche-uebersicht.tsx" /* webpackChunkName: "component---src-pages-archivsuche-uebersicht-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interviews-tsx": () => import("./../../../src/pages/interviews.tsx" /* webpackChunkName: "component---src-pages-interviews-tsx" */),
  "component---src-templates-interview-tsx": () => import("./../../../src/templates/interview.tsx" /* webpackChunkName: "component---src-templates-interview-tsx" */)
}

